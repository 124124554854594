import React, { useState, useEffect, useContext } from 'react'
import { Button, ListItem, ListItemText, Menu } from '@material-ui/core'
import { NavLink, useHistory } from 'react-router-dom'
import './DashboardSidePanel.css'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { SIDE_PANEL, SIDE_PANEL_ADMIN, SIDE_PANEL_END_SELLER } from '../../../Utils'
import { handleRedirectInternal, lowerCase } from '../../../common/components'
import AuthContext from '../../../context/auth/authContext'
import AdminAuthContext from '../../../context/admin/auth/authContext'

function DashboardSidePanel(props) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const history = useHistory()

    const authContext = useContext(AuthContext)
    const adminAuthContext = useContext(AdminAuthContext)

    const { user } = authContext
    const { user: admin } = adminAuthContext

    const admin_path = history.location.pathname.includes('/admin')

    console.log('user_admin', admin)

    var expand
    if (history.location.pathname.includes('/payments')) expand = 'acc_1'
    else if (
        history.location.pathname === '/customers' ||
        history.location.pathname === '/subscription' ||
        history.location.pathname === '/invoice'
    )
        expand = 'acc_3'
    else expand = false
    const [expanded, setExpanded] = useState(expand)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (history, path) => {
        setAnchorEl(null)
        // if (history && path && path !== 'backdropClick') {
        //     console.log('history', history, path)
        //     handleRedirectInternal(history, path)
        // }
    }

    const handleMenuList = (data, index) => {
        return (
            <>
                {data.type === 'single' ? (
                    <ListItem key={`${data.index}_parent`} button>
                        <NavLink to={data.path} exact activeClassName="active">
                            <ListItemText primary={data.label} />
                        </NavLink>
                    </ListItem>
                ) : (
                    <Accordion
                        expanded={expanded === `${data.path}`}
                        onChange={handleChange(`${data.path}`)}
                    >
                        <ListItem button>
                            <AccordionSummary
                                className={`${
                                    location.pathname.split('/')[1] === data.path && 'active'
                                }`}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}bh-content`}
                                id={`panel${index}bh-header`}
                            >
                                {data.label}
                            </AccordionSummary>
                        </ListItem>
                        <AccordionDetails>
                            <div className="subLinks">
                                {data.subLinks.map((d, i) => (
                                    <>
                                        <ListItem key={`${i}_child`} button>
                                            <NavLink to={d.path} exact activeClassName="active">
                                                <ListItemText primary={d.label} />
                                            </NavLink>
                                        </ListItem>
                                    </>
                                ))}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )}
            </>
        )
    }

    useEffect(() => {
        if (history.location.pathname.includes('/payments')) setExpanded('acc_1')
        else if (
            history.location.pathname === '/customers' ||
            history.location.pathname === '/subscription' ||
            history.location.pathname === '/invoice'
        )
            setExpanded('acc_3')
    }, [history.location])

    return (
        <div className="dashboardSidePanel">
            <div className="dashboardHome">
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    className="userDD"
                    // onClick={handleClick}
                >
                    <img src="/assets/svg/home.svg" />
                    {user?.company_name ? user.company_name : 'PAYMENTGATEWAY.IO'}
                    {/* <span className="material-icons" style={{ paddingLeft: '10px' }}>
                        expand_more
                    </span> */}
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                ></Menu>
            </div>
            {admin.emp_type == 'super_admin' && admin_path ? (
                <ul className="sidePanelLinks">
                    {SIDE_PANEL_ADMIN.map((data, index) => handleMenuList(data, index))}
                </ul>
            ) : (
                <ul className="sidePanelLinks">
                    {user?.role === 'admin'
                        ? SIDE_PANEL_ADMIN.map((data, index) => handleMenuList(data, index))
                        : user?.role === 'market_place' ||
                          (user?.role === 'sub_account' && user?.permissions)
                        ? SIDE_PANEL.map((data, index) => (
                              <>
                                  {user.permissions.indexOf(data.path.toString()) !== -1 &&
                                      handleMenuList(data, index)}
                              </>
                          ))
                        : user?.role === 'end_seller'
                        ? SIDE_PANEL_END_SELLER.map((data, index) => handleMenuList(data, index))
                        : null}
                </ul>
            )}
        </div>
    )
}

export default DashboardSidePanel
