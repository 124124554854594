import React from 'react'
import { useHistory } from 'react-router-dom'
import { Link, NavLink } from 'react-router-dom'
import PrimaryButton from '../../atoms/PrimaryButton'
import { handleRedirectInternal } from '../../../common/components'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
})

function Header() {
    const history = useHistory()
    const [mblVw, setMobileView] = React.useState(false)

    const [anchorEl, setAnchorEl] = React.useState(null)

    React.useEffect(() => {
        {
            window.innerWidth <= '991' ? setMobileView(true) : setMobileView(false)
        }
    }, [])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const classes = useStyles()
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    })

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }

    const menu = (
        <ul className="rtNav navbar-nav ml-auto">
            <li className="nav-item">
                <a
                    href="https://www.auctionsoftware.com/"
                    target="blank"
                    rel="noreferrer"
                    className="nav-link"
                >
                    About Us
                </a>
            </li>
            {mblVw ? (
                <>
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            target="blank"
                            rel="noreferrer"
                            href="https://documenter.getpostman.com/view/7222650/TVzSjHF5#0865b162-953a-47bd-8818-27f15b31ece1"
                        >
                            API Documentation
                        </a>
                    </li>
                    <li className="nav-item">
                        <Link
                            className="nav-link"
                            onClick={() => handleRedirectInternal(history, 'onboarding')}
                        >
                            Onboarding
                        </Link>
                    </li>
                </>
            ) : (
                <li className="nav-item">
                    <Button
                        className="dropBtn"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        Documentation <ExpandMoreIcon className="ml-1" />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>
                            <a
                                className="nav-link dropNavLink"
                                target="blank"
                                rel="noreferrer"
                                href="https://documenter.getpostman.com/view/7222650/TVzSjHF5#0865b162-953a-47bd-8818-27f15b31ece1"
                            >
                                API Documentation
                            </a>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleRedirectInternal(history, 'onboarding'), handleClose
                            }}
                        >
                            Onboarding
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleRedirectInternal(history, 'integrationdocument'), handleClose
                            }}
                        >
                            Integration Documentation
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleRedirectInternal(history, 'declinecodes'), handleClose
                            }}
                        >
                            Decline codes
                        </MenuItem>
                    </Menu>
                </li>
            )}
            <li className="nav-item">
                <Link
                    onClick={() => handleRedirectInternal(history, 'presentation')}
                    className="nav-link"
                >
                    Learn more
                </Link>
            </li>
            <li className="nav-item">
                <Link onClick={() => handleRedirectInternal(history, 'login')} className="nav-link">
                    Login
                </Link>
            </li>
            <li className="nav-item">
                <PrimaryButton
                    label="Get Started"
                    onClick={() => handleRedirectInternal(history, 'register')}
                />
            </li>
        </ul>
    )

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            {menu}
        </div>
    )

    return (
        <nav className="cstmNavbar navbar navbar-expand-lg navbar-light bg-light">
            <div
                className="wrapperNavbar"
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
            >
                <Link to="/" className="navbar-brand">
                    {/* <img
                        src="/assets/asLogo.svg"
                        alt="PAYMENTGATEWAY.IO Logo"
                        width="200"
                        height="60"
                    /> */}
                    <img
                        src="/assets/payment_gateway_name.svg"
                        alt="PAYMENTGATEWAY.IO Logo"
                        width="340"
                        height="60"
                    />
                </Link>
                <div
                    className="brandlogoHeader"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: '4px',
                        padding: '8px 0 0 0 ',
                    }}
                >
                    <span
                    // style={{ fontFamily: 'Playball', fontSize: '22px', padding: '0 6px 0 0' }}
                    >
                        Our Brands
                    </span>
                    <a href="https://www.auctionsoftware.com/" target="_blank" rel="noreferrer">
                        <img
                            src="/assets/auction_sftw_logo.svg"
                            alt="PAYMENTGATEWAY.IO Logo"
                            width="40"
                            height="40"
                        />
                    </a>

                    <a href="https://auction.io" target="_blank" rel="noreferrer">
                        <img
                            src="/assets/auctionio_logo.svg"
                            alt="PAYMENTGATEWAY.IO Logo"
                            width="40"
                            height="40"
                        />
                    </a>
                    <a href="https://paymentgateway.io" target="_blank" rel="noreferrer">
                        <img
                            src="/assets/payment_gateway_logo.svg"
                            alt="PAYMENTGATEWAY.IO Logo"
                            width="40"
                            height="40"
                        />
                    </a>
                    <a href="https://simpleauctionsite.com" target="_blank" rel="noreferrer">
                        <img
                            src="/assets/simple_auction_logo.svg"
                            alt="PAYMENTGATEWAY.IO Logo"
                            width="40"
                            height="40"
                        />
                    </a>
                    <a href="https://doorsey.com" target="_blank" rel="noreferrer">
                        <img
                            src="/assets/doorsdey_logo.svg"
                            alt="PAYMENTGATEWAY.IO Logo"
                            width="40"
                            height="40"
                        />
                    </a>
                </div>
            </div>

            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button className="navbar-toggler" onClick={toggleDrawer(anchor, true)}>
                        <span className="navbar-toggler-icon"></span>
                    </Button>
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                {menu}
            </div>
        </nav>
    )
}

export default Header
